import { useEffect } from "react";
import { useHistory } from "react-router";
import { AndroidLink, AppleLink } from "./App";

export default function Download() {
  const history = useHistory();

  useEffect(() => {
    console.log(window.navigator);
    const ua = window.navigator.userAgent;

    if (ua.includes("Android")) {
      window.location = AndroidLink;
    } else if (
      ua.includes("iPhone") ||
      ua.includes("iPad") ||
      ua.includes("Macintosh") ||
      ua.includes("iPod")
    ) {
      window.location = AppleLink;
    } else {
      history.push("/");
    }
    return () => {};
  }, []);
  return <h1></h1>;
}
