// var privacy = require("./privacy.html");

export default function Privacy() {
  return (
    <div
      classNameName="container"
      //   style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      <div classNameName="row justify-content-center ">
        <div classNameName="col-md-10">
          <iframe
            src="./privacy.pdf"
            style={{ width: "100%", height: "100vh", position: "absolute" }}
          >
            Your browser doesn't support iframes
          </iframe>
        </div>
      </div>
    </div>
  );
}
