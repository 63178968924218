export default function SponsorUs() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h1>Sponsor Us</h1>
          <p>
            If you're a Squishmallows retailers who is looking to be listed on
            Squish Alert app or a business who is looking join our Sponsorship
            Program, please contact us at
            <a
              className="link-secondary px-2"
              style={{ textDecoration: "none" }}
              href="mailto:hello@squishalert.com"
            >
              hello@squishalert.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
