export default function Terms() {
  return (
    <div className="container" style={{ height: "100%" }}>
      <div className="row justify-content-center">
        <div classNameName="col-md-10">
          <iframe
            src="./terms.pdf"
            style={{ width: "100%", height: "100vh", position: "absolute" }}
          >
            Your browser doesn't support iframes
          </iframe>
        </div>
      </div>
    </div>
  );
}
