import logo from "./logo.svg";
import "./App.css";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import Privacy from "./Privacy";
import Terms from "./Terms";
import SupportUs from "./SupportUs";

import ScrollSnap from "scroll-snap";
import { createRef, useEffect, useState } from "react";
import Features from "./Features";
import Statistics from "./Statistics";
import SponsorUs from "./SponsorUs";
import Sponsors from "./Sponsors";
import Testing from "./Testing";
import Download from "./Download";
import countries from "./countries.json";
import ReactCountryFlag from "react-country-flag";

export const AndroidLink =
  "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.squishalert_mobile";
export const AppleLink =
  "https://apps.apple.com/us/app/squish-alert/id1560369689";
function App() {
  return (
    <div className="">
      <header style={{ backgroundColor: "white" }}>
        <div className="container">
          <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
            <Link class="navbar-brand" to="/">
              <img
                src="/squish-alert-text.png"
                height="50"
                class="d-inline-block align-top"
                alt="SquishAlert logo"
              />
            </Link>
            <div className="d-flex flex-grow-1 justify-content-end">
              <ul class="navbar-nav">
                <li class="nav-item dropdown " style={{ marginRight: 15 }}>
                  <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Support us
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <a
                        href="https://www.paypal.com/donate/?hosted_button_id=6X43CPNT5B9YW"
                        target="_blank"
                        className="dropdown-item"
                      >
                        Donate
                      </a>
                    </li>
                    <li>
                      <Link to="/sponsor-us" class="dropdown-item">
                        Sponsor us
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li class="nav-item">
                  <a
                    className="nav-link"
                    href="https://app.squishalert.com"
                    style={{
                      backgroundColor: "green",
                      borderRadius: 30,
                      color: "white",
                      padding: 10,
                    }}
                  >
                    Sign in
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Redirect from="/privacy" to="/privacy-policy" />
          <Redirect from="/terms" to="/terms-of-use" />
          <Route exact path="/terms-of-use" component={Terms} />
          <Route exact path="/sponsor-us" component={SponsorUs} />
          <Route
            path="/support-us"
            component={() => {
              window.location.href =
                "https://www.paypal.com/donate/?hosted_button_id=6X43CPNT5B9YW";
              return null;
            }}
          />
          {/* <Route exact path="/support-us" component={SupportUs} /> */}
          <Route exact path="/download" component={Download} />

          <Redirect from="/support" to="/support-us" />
          <Redirect from="/supportus" to="/support-us" />
          <Redirect from="/sponsor" to="/sponsor-us" />
          <Redirect from="/canada" to="/download" />
          <Redirect from="/uk" to="/download" />
          <Redirect from="/UK" to="/download" />
          <Redirect from="/australia" to="/download" />
          <Redirect from="*" to="/" />
        </Switch>
      </main>
    </div>
  );
}
function Footer() {
  return (
    <footer className="container pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-6 col-md">
          <img
            class="mb-2"
            src="/squish-alert-frog.png"
            alt="squishalert-logo"
            height="40"
          />
          <small class="d-block mb-3 text-muted">© 2021</small>
        </div>
        <div class="col-6 col-md">
          <h5>About</h5>
          <ul class="list-unstyled text-small">
            <li class="mb-1">
              <Link
                class="link-secondary text-decoration-none"
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </li>
            <li class="mb-1">
              <Link
                class="link-secondary text-decoration-none"
                to="/terms-of-use"
              >
                Terms of Use
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-row align-items-center text-secondary">
            <i class="far fa-envelope fa-lg" style={{ width: 20 }}></i>
            <a
              className="link-secondary px-2"
              style={{ textDecoration: "none" }}
              href="mailto:hello@squishalert.com"
            >
              : hello@squishalert.com
            </a>
          </div>
          <div>
            <div className="d-flex flex-row align-items-center text-secondary">
              <i className="fab fa-instagram fa-lg" style={{ width: 20 }}></i>
              <a
                className="link-secondary px-2"
                style={{ textDecoration: "none" }}
                href="https://instagram.com/squishalert.app"
                target="_blank"
              >
                : @squishalert.app
              </a>
            </div>
            <div className="d-flex flex-row align-items-center text-secondary">
              <i className="fab fa-facebook fa-lg" style={{ width: 20 }}></i>
              <a
                className="link-secondary px-2"
                style={{ textDecoration: "none" }}
                href="https://facebook.com/squishalert"
                target="_blank"
              >
                : Squish Alert
              </a>
            </div>
            <div className="d-flex flex-row align-items-center text-secondary">
              <i className="fab fa-twitter " style={{ width: 20 }}></i>
              <a
                className="link-secondary px-2"
                style={{ textDecoration: "none" }}
                href="https://twitter.com/squishalert"
                target="_blank"
              >
                : @SquishAlert
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
function Home() {
  const sortCountries = countries.sort((a, b) =>
    a.iso.toLowerCase() < b.iso.toLowerCase() ? -1 : 1
  );
  const [showCountries, setShowCountries] = useState(false);

  useEffect(() => {
    bindScrollSnap();
    return () => {};
  }, []);
  const container = createRef();

  function callback() {}
  function bindScrollSnap() {
    const element = container.current;
    const snapElement = new ScrollSnap(element, {
      snapDestinationY: "90%",
    });

    snapElement.bind(callback);
  }
  return (
    <div id="container" ref={container}>
      <div className="page first-page" id="page1">
        <div
          style={{
            // backgroundColor: "green",
            // height: "100vh",
            // width: "100px",
            borderLeft: "700px solid transparent",
            // borderRight: "500px solid transparent",
            borderTop: "100vh solid green",
            position: "absolute",
            right: 0,
            zIndex: -1,
          }}
        ></div>
        <div
          className="d-flex flex-row align-items-center justify-content-center"
          style={{ width: "90%" }}
        >
          <img
            className="d-none d-lg-block"
            src="/screenshot (4).png"
            height="400px"
            style={{ marginBottom: 20, marginRight: -50, zIndex: -1 }}
          />
          <img
            className="d-none d-lg-block "
            src="/screenshot (2).png"
            height="400px"
          />
          <img
            className="d-none d-lg-block"
            src="/screenshot (5).png"
            height="400px"
            style={{ marginBottom: 20, marginLeft: -50, zIndex: -1 }}
          />

          <div style={{ margin: 0 }}>
            <div
              style={{
                fontSize: 40,
                fontWeight: "bold",
                textShadow: "2px 2px #fff",
                overflowWrap: "break-word",
              }}
            >
              Squishmallows Restock Alerts
            </div>
            <div style={{ fontSize: 20 }}>
              Squish Alert terminated all services on March 09, 2023. If you
              wish to become the new owner of the Squish Alert service (mobile
              app and social sites), please contact{" "}
              <a href="mailto:hello@squishalert.com">hello@squishalert.com</a>
            </div>
            <div style={{ marginTop: 10 }}>
              Squish Alert had 170,000 users. Squish Alert is a <b>FREE</b>{" "}
              mobile app that helps you get your ISO (in-search-of) at retail
              prices (no more purchasing from price gougers). <br />
              You can easily find restocks in your local areas, from authorized
              online retailers , look up Squishmallows biography/stores where
              they came from and keep track of your collections (Available
              worldwide).
            </div>
            {/* <div className="">
              <div className="d-flex flex-md-row flex-column">
                <a style={{ padding: 12 }} href={AppleLink} target="_blank">
                  <img src="/download_appstore_badge.svg" height="60" />
                </a>
                <a href={AndroidLink} target="_blank">
                  <img src="/download_googlestore_badge.png" height="85" />
                </a>
              </div>
            </div>
            <div style={{ marginTop: 5, fontWeight: "bold", color: "grey" }}>
              If you are a brand looking for a partnership or sponsoring, please{" "}
              <a href="mailto:hello@squishalert.com">contact us here</a>
            </div> */}
          </div>
        </div>
        <div className="hint">
          <i className="fas fa-chevron-down text-secondary"></i>
        </div>
      </div>
      <Features />
      <Sponsors />
      {/* <Statistics /> */}

      <Footer />
    </div>
  );
}
const styles = {
  downloadButton: {
    border: "2px solid black",
    padding: 20,
    margin: 5,
  },
};
export default App;
