export default function Features() {
  return (
    <div className="page second-page">
      <div className="page-title" style={{ marginBottom: "30px" }}>
        Features
      </div>
      <div
        className="feature-card  d-flex flex-md-row flex-column-reverse "
        style={{ width: "90%" }}
      >
        <img
          src="/feature1.png"
          style={{
            marginTop: -50,
            maxHeight: "400px",
            maxWidth: "100vw",
            width: "auto",
            height: "auto",
          }}
        />
        <div style={{ backgroundColor: "white", minWidth: "40%" }}>
          <h3>
            Restock Alerts - Get Squishmallows shipped to your door step with
            online restock alerts
          </h3>
          <p>
            No more driving or overpaying for Squishmallows! Squish Alert will
            send you real-time push notifications when authorized Squishmallows
            retailers restock online.
          </p>
        </div>
      </div>

      <div
        className=" feature-card d-flex flex-md-row flex-column"
        style={{ width: "90%" }}
      >
        <div style={{ backgroundColor: "white", minWidth: "40%" }}>
          <h3>Squish News - Find out what is restocked in your area</h3>
          <p>
            You can see which stores, in your area or around the world, have new
            restocks from news shared by your local collector buddies!
          </p>
        </div>

        <img
          src="/feature2.png"
          style={{
            marginTop: -50,
            maxHeight: "400px",
            maxWidth: "100vw",
            width: "auto",
            height: "auto",
          }}
        />
      </div>

      <div
        className=" feature-card d-flex flex-md-row  flex-column-reverse "
        style={{ width: "90%" }}
      >
        <img
          src="/feature3.png"
          style={{
            marginTop: -100,
            maxHeight: "500px",
            maxWidth: "100vw",
            width: "auto",
            height: "auto",
          }}
        />
        <div style={{ backgroundColor: "white", minWidth: "40%" }}>
          <h3>Squishionary - A dictionary of Squishmallows</h3>
          <p>
            See new Squishmallows <b>styles</b>, <b>stores</b> they were found
            at, their <b>biographies</b>, <b>Squishdate</b>,{" "}
            <b>Collector Number</b>, <b>sizes</b>, etc, and Bookmark them for
            your collection or your wishlist.
          </p>
        </div>
      </div>
      <div
        className=" feature-card d-flex flex-md-row flex-column"
        style={{ width: "90%" }}
      >
        <div style={{ backgroundColor: "white", minWidth: "40%" }}>
          <h3>
            Squish Q&A - Get answers for any Squishmallows questions you have
          </h3>
          <p>
            Whether it's about where to find a certain style or a future
            restock, your Squish fellows have got your back!
          </p>
        </div>

        <img
          src="/feature4.png"
          style={{
            marginTop: -50,
            maxHeight: "400px",
            maxWidth: "100vw",
            width: "auto",
            height: "auto",
          }}
        />
      </div>
    </div>
  );
}
