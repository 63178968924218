export default function Sponsors() {
  return (
    <div className="page second-page">
      <div className="page-title" style={{ marginBottom: "30px" }}>
        Our Sponsors
      </div>
      <div className="d-flex flex-row">
        {/* <div style={{ marginRight: 50 }}>
          <a href="https://www.toynk.com/" target="_blank">
            <img src="./toynk_logo2.png" style={{ height: "100px" }} />
          </a>
        </div> */}
        <div>
          <a href="https://www.letsjolly.com/" target="_blank">
            <img src="./jolly_logo.png" style={{ height: "100px" }} />
          </a>
        </div>
      </div>
      <div className="fw-bold fs-5 mt-3">
        If you are interested in joining our Sponsorship Program, please email
        us at{" "}
        <a
          className="link-secondary px-2 "
          style={{ textDecoration: "none" }}
          href="mailto:hello@squishalert.com"
        >
          hello@squishalert.com
        </a>
      </div>
    </div>
  );
}
