import { Fragment } from "react";

export default function SupportUs() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h1 style={{ textShadow: "2px 1px 1px #fff", textAlign: "center" }}>
            Support Us
          </h1>
          <hr />
          <div
            className="col-md-6 text-align-center"
            style={{
              fontFamily:
                "TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif",
              fontSize: 30,
              fontStyle: "italic",
              backgroundColor: "#ffffffdb",
              padding: 10,
              borderRadius: 20,
            }}
          >
            <i className="fas fa-quote-left text-success"></i>With just 50
            cents, you can help Squish Alert run <b>free</b> for everyone around
            the world.
            <i className="fas fa-quote-right text-success"></i>
          </div>
          <br />
          <ProgressBar
            // key={idx}
            bgcolor={"green"}
            completed={"10"}
          />
          <div className="m-3">
            <a
              className="btn btn-warning ml-3"
              href="https://www.paypal.com/donate/?hosted_button_id=6X43CPNT5B9YW"
              target="_blank"
            >
              DONATE NOW
            </a>
          </div>
          <div className="d-flex flex-row" style={{ marginTop: 50 }}>
            <h2 style={{ textShadow: "2px 1px 1px #fff" }}>Why donate?</h2>
          </div>
          <p>
            Any amount could make an impact.
            <br />
            Squish Alert is the first software that notifies Squishmallows
            collectors when Squishmallows retailers restock. You might or might
            not know that Squish Alert was made by{" "}
            <span style={{ fontSize: 25 }}>team of one</span> and everything was
            paid out of one individual's pocket. Your donation will help Squish
            Alert continue to run <b>smoothly</b> and <b>freely</b> (no premium
            account at all) for everyone around the world.
          </p>
          <div
            className="col-md-6 text-align-center"
            style={{
              fontFamily:
                "TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif",
              fontSize: 30,
              fontStyle: "italic",
              backgroundColor: "#ffffffdb",
              padding: 10,
              borderRadius: 20,
            }}
          >
            Our donors will the THE FIRST people to have access to our new
            future features.
          </div>
          <br />
          <h2 style={{ textShadow: "2px 1px 1px #fff", marginTop: 50 }}>
            Why Your Donation is Important?
          </h2>
          <p>
            There are reoccuring fees like hosting, server, database, etc. Your
            donation will allow Squish Alert to{" "}
            <span style={{ fontSize: 25 }}>
              expand the app to other countries
            </span>{" "}
            where Squishmallows is available. We will continue to add more sites
            and features and improve the app's performance. Squish Alert right
            now is only available to US residents and as we started to gain more
            users (currently we have over 6700 users as of May 10, 2021, and{" "}
            <span style={{ fontSize: 25 }}>31,763 users*</span> as of Sep 15,
            2021 , Squish Alert has encountered quite a few crashes and delay
            due to the heavy traffic on the low-cost server that we are using.
            <br />
          </p>
          <p>Your donation will help us pay for</p>
          <ul>
            <li>Legal fee (one time $1500)</li>
            <li>
              App store hosting fee ($100 <u>per year</u>)
            </li>
            <li>Google Play store hosting fee (one time $25)</li>
            <li>
              Server fee $100-$200 <u>per month</u> (fee increases as there are
              more users)
            </li>
            <li>
              Database fee $100-$200 <u>per month</u>(fee increases as there are
              more users)
            </li>
            <li>
              Other miscellaneous fees like sending SMS/email $80-$150{" "}
              <u>per month</u> (fee increases as there are more users)
            </li>
          </ul>
          <div className="m-1">
            <a
              className="btn btn-warning ml-3"
              href="https://www.paypal.com/donate/?hosted_button_id=6X43CPNT5B9YW"
              target="_blank"
            >
              DONATE NOW
            </a>
          </div>
          <small>
            Already donate?{" "}
            <a
              className="text-sm"
              href="https://forms.gle/Bnjkp3WDxd8LTqReA"
              target="_blank"
            >
              Click here
            </a>
          </small>
          <br />
          <small>
            *Despite the number of users increased by 5 times since we first
            made this page in May 10, 2021, we still keep our goal of $3000 the
            same as before. The cost of the server, database and other fees
            increased drastically due to the increase in our users and the data
            in the system. We will keep the app running as long as possible from
            the donation and ads money but do understand this might not keep the
            app running forever so more donations are much welcomed!
          </small>
        </div>
      </div>
    </div>
  );
}

const ProgressBar = (props) => {
  const raised = 804.5,
    goal = 3000;
  const completed = ((raised / goal) * 100).toFixed(2);
  const bgcolor = "skyblue";
  // const { bgcolor, completed } = props;

  const containerStyles = {
    height: 50,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    // margin: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    maxWidth: "100%",
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
  };

  const labelStyles = {
    padding: 10,
    color: "white",
    fontWeight: "bold",
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Fragment>
      <h3>
        Raised ${numberWithCommas(raised)} out of ${numberWithCommas(goal)}
      </h3>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${completed}%`}</span>
        </div>
      </div>
    </Fragment>
  );
};
